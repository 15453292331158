.store-title {
  height: 72px;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 72px;
}
.store-subtitle {
  height: 28px;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 28px;
  margin-bottom: 28px;
}
.title-style {
  color: #fff;
  font-family: 'Roboto';
  font-weight: normal;
}
.d-flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 122px;
}
.search-button {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 64px;
  height: 64px;
  cursor: pointer;
}
.search-button > svg {
  margin-top: 15px;
  color: #fff;
  width: 35px;
  height: 35px;
}
.input-container {
  text-align: center;
  width: 496px;
  height: 64px;
  position: relative;
  @media only screen and (max-width: 496px) {
    width: inherit;
  }
}
.store-search > div {
  padding: 0 !important;
}
ul.store-list-options {
  padding: 0px;
  margin: 0px;
  height: 300px;
  overflow: scroll;
  background: #fff;
}
ul.store-list-options li {
  white-space: pre-wrap;
  border-bottom: 1px solid #ccc;
}
.list-option {
  display: flex;
}
.option-store-info {
  color: #000000;
  font-family: 'Roboto';
  font-weight: normal;
  height: 28px;
  letter-spacing: 0px;
  line-height: 28px;
}
