body {
  margin: 0;
  font-family: Roboto, Roboto-Medium;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.time {
  font-size: 16px !important;
}
